import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** 成交量 */
export const winner_tsea_volume = createIndicator({
  displayName: '成交量',
  id: 'winnertseavolume',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const volume = this.PineJS.Std.volume(this._context) / 1e8

      const colorIndex = close_array.get(0) >= close_array.get(1) ? 0 : 1

      return [volume, colorIndex]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'vol',
        type: 'line',
      },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
    ],

    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
        },
      },
    },

    defaults: {
      //樣式調整
      styles: {
        //--
        plot_0: {
          transparency: 0,
          visible: !0,
        },
        //--
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 30,
          visible: !0,
          color: '#02aa22',
        },
      },
      //填充區域
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#cf304a',
              width: 0,
              style: 0,
            },
            1: {
              color: '#02aa22',
              width: 0,
              style: 0,
            },
          },
        },
      },
    },
    precision: 2,
    is_price_study: !1,
    inputs: [],
    scriptIdPart: '',
  },
})
