/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { indicatorsValueStore } from '~/pages/winnerA/modules/indicatorsValueStore'

export const winner_tx_powerline2 = createIndicator({
  id: 'winnertxpowerline2',
  displayName: '趨勢力道線',
  constructorScope: {
    init(context, inputCallback) {
      return
    },

    main(context, inputCallback) {
      const linregSlope = function (iclose: any, in1: number, in2: number) {
        let n = NaN
        let o = NaN
        let r = NaN
        let s = 0
        let a = 0
        let l = 0
        let c = 0

        for (n = 0; n < in1; ++n)
          (o = iclose.get(n)), (s += r = in1 - 1 - n + 1), (a += o), (l += r * r), (c += o * r)

        return (in1 * c - s * a) / (in1 * l - s * s)
      }

      const in1 = 27
      const in2 = 6

      const c = this.PineJS.Std.close(this._context)
      const vc = this._context.new_var(c)
      const v = this.PineJS.Std.linreg(vc, in1, 0)
      const nv = this._context.new_var(v)

      const slope = linregSlope(vc, in1, 0)
      const nslope = this._context.new_var(slope)

      const slope_ma = this.PineJS.Std.alma(nslope, in2, 0.8, 6)

      const colorIndex = () => {
        if (nslope.get(0) >= slope_ma) return 0
        else return 1
      }
      indicatorsValueStore.trendPower = v
      
      return [v, colorIndex()]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,

    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'colorer',
        target: 'plot_0',
        palette: 'palette_0',
      },
    ],
    palettes: {
      palette_0: {
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
        },
      },
    },
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#000000',
        },
      },
      //填充區域
      palettes: {
        palette_0: {
          colors: {
            0: {
              color: '#ff5b5b',
              width: 2,
              style: 0,
            },
            1: {
              color: '#00e600',
              width: 2,
              style: 0,
            },
          },
        },
      },
      precision: 0,
      inputs: {},
    },
    styles: {
      plot_0: {
        title: '趨勢力道線',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    inputs: [
      // { id: 'in_0', name: '', defval: 27, type: 'integer', min: 1, max: 120 },
      // { id: 'in_1', name: '', defval: 6, type: 'integer', min: 1, max: 100 },
    ],
  },
})
