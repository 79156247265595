import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

export const winner_test_ti = createIndicator({
  id: 'winnertestti',
  displayName: '掛單能量',
  constructorScope: {
    init(context, inputcallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#trade_info'
      context.new_sym(symbol, this.PineJS.Std.period(context))
    },
    main(context, inputcallback) {
      context.select_sym(1)
      const o = this.PineJS.Std.open(context)
      return [o]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [{ id: 'plot_0', type: 'line' }],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 4,
          trackPrice: false,
          transparency: 0,
          color: '#0000ff',
        },
      },

      // Precision is 0 digit, only integer
      precision: 0,

      inputs: {},
    },
    styles: {
      plot_0: {
        title: '大戶掛單',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
