import { useIndicatorStore } from '~/store/useIndicatorStore'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore'

const sounds = useSoundStore.getState()

export const winner_all_diamond = createIndicator({
  displayName: '順勢買賣訊',
  id: 'winneralldiamond',
  constructorScope: {
    init(context, inputCallback) {
      localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString())
    },
    main(context, inputCallback) {
      const state = useIndicatorStore.getState()
      //SAR1
      const input0 = 0.0136
      //SAR2
      const input1 = 0.0002
      //SAR3
      const input2 = 0.02

      const sar0 = input0
      const sar1 = input1
      const sar2 = input2

      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const open = this.PineJS.Std.open(this._context)
      const low = this.PineJS.Std.low(this._context)
      const low_array = this._context.new_var(low)
      const high = this.PineJS.Std.high(this._context)
      const high_array = this._context.new_var(high)
      const itime = this.PineJS.Std.time(this._context)
      const itime_array = this._context.new_var(itime)
      const sar_value0 = this.PineJS.Std.sar(sar0, sar1, sar2, this._context)
      const sar_value0_array = this._context.new_var(sar_value0)

      const bState = this._context.new_var()
      const entryPrice = this._context.new_var()
      const entryTime = this._context.new_var()
      const stopLoss_long = this._context.new_var()
      const stopLoss_short = this._context.new_var()

      let B_signal = NaN
      let S_signal = NaN

      bState.get(1)
      bState.get(2)
      low_array.get(1)
      high_array.get(1)
      close_array.get(1)
      sar_value0_array.get(1)
      entryPrice.get(1)
      itime_array.get(1)

      const buy_condition = close_array.get(0) > sar_value0_array.get(0)
      const short_condition = close_array.get(0) < sar_value0_array.get(0)

      if (buy_condition) {
        bState.set(1)
      } else if (short_condition) {
        bState.set(-1)
      }

      if (bState.get(0) === 1 && bState.get(1) !== 1) {
        B_signal = 1
        S_signal = NaN
        entryPrice.set(close)
        entryTime.set(itime)
        stopLoss_long.set(close * 0.998)
        stopLoss_short.set(NaN)
      } else if (bState.get(0) === -1 && bState.get(1) !== -1) {
        S_signal = 1
        B_signal = NaN
        entryPrice.set(close)
        entryTime.set(itime)
        stopLoss_short.set(close * 1.002)
        stopLoss_long.set(NaN)
      }

      if (itime_array.get(30) > entryTime.get(0)) {
        stopLoss_long.set(NaN)
        stopLoss_short.set(NaN)
      }

      if (
        bState.get(0) === 1 &&
        bState.get(1) === 1 &&
        low_array.get(0) < entryPrice.get(0) * 0.998
      ) {
        stopLoss_long.set(NaN)
      }

      if (
        bState.get(0) === -1 &&
        bState.get(1) === -1 &&
        high_array.get(0) > entryPrice.get(0) * 1.002
      ) {
        stopLoss_short.set(NaN)
      }

      //聲音------------------------------------------------------------------
      const displayTime = new Date(itime).toLocaleTimeString('en-US')
      const kbarLoadingTimeMs = 10000
      const isInitialized =
        new Date().getTime() >
        parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs
      const new_bar = this._context.symbol.isNewBar

      /** 進場部位聲音 */
      if (isInitialized && new_bar) {
        if (bState.get(1) === 1 && bState.get(2) !== 1 && entryTime.get(0) === itime_array.get(1)) {
          sounds.playSoundWinnerLongDebounced()
          console.log('✅', displayTime, '進多單')
        } else if (
          bState.get(1) === -1 &&
          bState.get(2) !== -1 &&
          entryTime.get(0) === itime_array.get(1)
        ) {
          sounds.playSoundWinnerShortDebounced()
          console.log('✅', displayTime, '進空單')
        }
      }

      return [B_signal, S_signal, stopLoss_long.get(0), stopLoss_short.get(0)]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'chars',
      },
      {
        id: 'plot_1',
        type: 'chars',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
      {
        id: 'plot_3',
        type: 'line',
      },
    ],

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          color: '#ffff00',
          textColor: '#ffff00',
          transparency: 20,
          visible: true,
        },
        plot_1: {
          color: '#00ffff',
          textColor: '#00ffff',
          transparency: 20,
          visible: true,
        },
        plot_2: {
          linestyle: 1,
          linewidth: 1,
          plottype: 7,
          trackPrice: !1,
          transparency: 15,
          visible: !1,
          color: '#ffaa00',
        },
        plot_3: {
          linestyle: 1,
          linewidth: 1,
          plottype: 7,
          trackPrice: !1,
          transparency: 15,
          visible: !1,
          color: '#00aaff',
        },
      },
      // inputs: { in_0: 0.0136, in_1: 0.0002, in_2: 0.2 },
    },

    styles: {
      plot_0: {
        isHidden: false,
        location: 'BelowBar',
        char: '🌟',
        size: 'small',
        text: '',
      },
      plot_1: {
        isHidden: false,
        location: 'AboveBar',
        char: '💎',
        size: 'small',
        text: '',
      },
      plot_2: {
        title: '星星持單防守線',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_3: {
        title: '鑽石持單防守線',
        histogramBase: 0,
        joinPoints: !1,
      },
    },

    is_price_study: !0,
    inputs: [
      // { id: 'in_0', name: 'sar初始', defval: 0.026, type: 'float', min: 0, max: 100 },
      // { id: 'in_1', name: 'sar增量', defval: 0.01, type: 'float', min: 0, max: 100 },
      // { id: 'in_2', name: 'sar最大', defval: 0.2, type: 'float', min: 0, max: 100 },
    ],
    scriptIdPart: '',
  },
})
