import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const winner_tx_kbar = createIndicator({
  displayName: '多空分界',
  id: 'winnertxkbar',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const interval = this.PineJS.Std.interval(this._context)

      const in1 = 38
      const in2 = 58

      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)

      const sma1 = this.PineJS.Std.wma(close_array, in1, this._context)
      const sma2 = this.PineJS.Std.wma(close_array, in2, this._context)

      const longStrong = close > sma1 && close > sma2
      const shortStrong = close < sma1 && close < sma2

      const colorIndex = longStrong ? 1 : shortStrong ? 3 : 2

      return [colorIndex]
    },
  },
  metainfo: {
    plots: [
      {
        id: '多空顏色',
        type: 'bar_colorer',
        palette: 'palette_0',
      },
    ],

    defaults: {
      // inputs: { in_0: 38, in_1: 58 },
    },

    palettes: {
      palette_0: {
        colors: {
          0: {
            color: '#ec407a',
            width: 1,
            style: 0,
          },
          1: {
            color: '#ff0000',
            width: 1,
            style: 0,
          },
          2: {
            color: '#ffff00',
            width: 1,
            style: 0,
          },
          3: {
            color: '#00ff00',
            width: 1,
            style: 0,
          },
        },
      },
    },
    is_price_study: !0,
    inputs: [
      // { id: 'in_0', name: '參數1', defval: 38, type: 'integer', min: 1, max: 120 },
      // { id: 'in_1', name: '參數2', defval: 58, type: 'integer', min: 1, max: 120 },
    ],
    scriptIdPart: '',
  },
})
