import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { indicatorsValueStore } from '~/pages/winnerA/modules/indicatorsValueStore'

/** 60/20日移動平均線 */
export const winner_tsea_ma = createIndicator({
  displayName: '移動平均',
  id: 'winnertseama',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const in1 = 5
      const in2 = 20
      const in3 = 60

      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)

      const sma1 = this.PineJS.Std.sma(close_array, in1, this._context)
      const sma2 = this.PineJS.Std.sma(close_array, in2, this._context)
      const sma3 = this.PineJS.Std.sma(close_array, in3, this._context)
      indicatorsValueStore.close = close

      return [sma1, sma2, sma3]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'line',
      },
    ],

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ffee58',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ec407a',
        },
        plot_3: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#42a5f5',
        },
      },
      //填充區域
    },
    styles: {
      plot_0: {
        title: '5日',
        histogramBase: 0,
      },
      plot_1: {
        title: '20日',
        histogramBase: 0,
      },
      plot_2: {
        title: '60日',
        histogramBase: 0,
      },
    },

    is_price_study: !0,
    inputs: [],
    scriptIdPart: '',
  },
})
