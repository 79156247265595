import { useIndicatorStore } from '~/store/useIndicatorStore'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const winner_tx_bisa = createIndicator({
  displayName: '乖離訊',
  id: 'winnertxbisa',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const state = useIndicatorStore.getState()

      const in1 = 63
      const in2 = 0.002
      const in3 = this._input(0)

      const high = this.PineJS.Std.high(this._context)
      const low = this.PineJS.Std.low(this._context)
      const close = this.PineJS.Std.hlc3(this._context)
      const close_array = this._context.new_var(close)
      const high_array = this._context.new_var(high)
      const low_array = this._context.new_var(low)
      const bState = this._context.new_var()

      let B_signal = NaN
      let S_signal = NaN

      bState.get(1)

      const sma1 = this.PineJS.Std.ema(close_array, in1, this._context)

      const vHn = this.PineJS.Std.highest(high_array, 18, this._context)
      const vLn = this.PineJS.Std.lowest(low_array, 18, this._context)
      const vrsv = ((close - vLn) / (vHn - vLn)) * 100

      const vk_array = this._context.new_var()
      const s = (1.0 / 8) * vrsv + (1 - 1.0 / 8) * vk_array.get(1)
      if (isNaN(s)) {
        vk_array.set(0)
      } else {
        vk_array.set(s)
      }

      const vd_array = this._context.new_var()
      const q = (1.0 / 6) * vk_array + (1 - 1.0 / 6) * vd_array.get(1)
      if (isNaN(q)) {
        vd_array.set(0)
      } else {
        vd_array.set(q)
      }

      const r_array = this._context.new_var()
      const r = vk_array.get(0) - vd_array.get(0)
      r_array.set(r)
      r_array.get(0)
      r_array.get(1)

      const buy_condition =
        in3 === 0
          ? low < sma1 * 0.998
          : low < sma1 * 0.998 && r_array.get(0) < 0 && r_array.get(0) > r_array.get(1)
      const short_condition =
        in3 === 0
          ? high > sma1 * 1.002
          : high > sma1 * 1.002 && r_array.get(0) > 0 && r_array.get(0) < r_array.get(1)

      if (buy_condition) {
        bState.set(1)
      } else if (short_condition) {
        bState.set(-1)
      }

      if (bState.get(0) === 1 && bState.get(1) !== 1) {
        B_signal = 1
        S_signal = NaN
      } else if (bState.get(0) === -1 && bState.get(1) !== -1) {
        S_signal = 1
        B_signal = NaN
      }

      state.update(state)
      return [B_signal, S_signal]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'chars',
      },
      {
        id: 'plot_1',
        type: 'chars',
      },
    ],

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          color: '#ff79ff',
          textColor: '#ff79ff',
          transparency: 20,
          visible: true,
        },
        plot_1: {
          color: '#ffffff',
          textColor: '#ffffff',
          transparency: 20,
          visible: true,
        },
      },
      inputs: { in_0: 1 },
    },

    styles: {
      plot_0: {
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '',
      },
      plot_1: {
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '',
      },
    },

    is_price_study: !0,
    inputs: [
      // { id: 'in_0', name: '均線', defval: 63, type: 'integer', min: 1, max: 120 },
      // { id: 'in_1', name: '乖離', defval: 0.002, type: 'float', min: 0, max: 10 },
      { id: 'in_0', name: '正常0/加強1', defval: 0, type: 'float', min: 0, max: 1 },
    ],
    scriptIdPart: '',
  },
})
