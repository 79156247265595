import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { indicatorsValueStore } from '~/pages/winnerA/modules/indicatorsValueStore'

export const winner_tx_ma = createIndicator({
  displayName: '市場成本線',
  id: 'winnertxma',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const in1 = 55
      const in2 = 67

      const symbol = this.PineJS.Std.ticker(this._context)
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)

      const sma1 = this.PineJS.Std.wma(close_array, in1, this._context)
      const sma2 = this.PineJS.Std.wma(close_array, in2, this._context)

      const colorIndex = sma1 - sma2 >= 0 ? 0 : 1
      indicatorsValueStore.constLine = sma1
      indicatorsValueStore.close = close
      indicatorsValueStore.symbol = symbol

      return [sma1, sma2, colorIndex]
    },
  },
  metainfo: {
    //宣告
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
      {
        id: 'plot_2',
        type: 'colorer',
        target: 'filledAreaId1',
        palette: 'paletteId1',
      },
    ],

    filledAreas: [
      {
        id: 'filledAreaId1',
        objAId: 'plot_1',
        objBId: 'plot_0',
        type: 'plot_plot',
        title: '趨勢指標',
        palette: 'paletteId1',
      },
    ],

    palettes: {
      paletteId1: {
        valToIndex: {
          0: 0,
          1: 1,
        },
        colors: {
          0: {
            name: '多方趨勢',
          },
          1: {
            name: '空方趨勢',
          },
        },
      },
    },

    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !0,
          color: '#11ff11',
        },
        plot_1: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !0,
          color: '#ff1111',
        },
      },
      //填充區域
      filledAreasStyle: {
        filledAreaId1: {
          color: '#0000ff',
          transparency: 0,
          visible: !0,
        },
      },
      palettes: {
        paletteId1: {
          colors: {
            0: {
              color: '#fe80c0',
              width: 1,
              style: 0,
            },
            1: {
              color: '#7bc2ff',
              width: 1,
              style: 1,
            },
          },
        },
      },
      // inputs: { in_0: 55, in_1: 67 },
    },

    is_price_study: !0,
    inputs: [
      // { id: 'in_0', name: '參數1', defval: 55, type: 'integer', min: 1, max: 120 },
      // { id: 'in_1', name: '參數2', defval: 67, type: 'integer', min: 1, max: 120 },
    ],
    scriptIdPart: '',
  },
})
