import { proxy } from 'valtio'

/** 專屬 winnerA 指標傳參數 */
export const indicatorsValueStore = proxy({
  symbol: '' as string,
  /** 現價 */
  close: NaN as number,
  /** 當沖力道 */
  daytradePower: NaN as number,
  /** 趨勢力道 */
  trendPower: NaN as number,
  /** 成本線 */
  constLine: NaN as number,
  /** 攻擊線 */
  attackLine: NaN as number,
  /** 大戶籌碼 */
  bigtraderChip: NaN as number,
  /** 趨勢對決線上 趨勢對決線下*/
  trendLine: [NaN, NaN] as number[],
  /** 趨勢對決線上 趨勢對決線下*/
  bumpyLine: [NaN, NaN] as number[],
})

export const resetIndicatorsValue = () => {
  indicatorsValueStore.close = NaN
  indicatorsValueStore.daytradePower = NaN
  indicatorsValueStore.trendPower = NaN
  indicatorsValueStore.constLine = NaN
  indicatorsValueStore.attackLine = NaN
  indicatorsValueStore.bigtraderChip = NaN
  indicatorsValueStore.trendLine = [NaN, NaN]
  indicatorsValueStore.bumpyLine = [NaN, NaN]
  indicatorsValueStore.trendLine = [NaN, NaN]
}
