/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { useIndicatorStore } from '~/store/useIndicatorStore'
import { indicatorsValueStore } from '~/pages/winnerA/modules/indicatorsValueStore'

export const winner_tx_priceline309 = createIndicator({
  id: 'winnertxpriceline309',
  displayName: '盤整防守線',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const state = useIndicatorStore.getState()
      const high = this.PineJS.Std.high(this._context)
      const high_array = this._context.new_var(high)
      const daily_high = this._context.new_var()
      const low = this.PineJS.Std.low(this._context)
      const low_array = this._context.new_var(low)
      const daily_low = this._context.new_var()
      const close = this.PineJS.Std.close(this._context)
      const open = this.PineJS.Std.open(this._context)
      const close_array = this._context.new_var(close)
      const interval = context.symbol.period

      const itime = this.PineJS.Std.time(this._context)
      const start_h = new Date(itime).getHours()
      const start_m = new Date(itime).getMinutes()

      const pc_high = this._context.new_var()
      const pc_low = this._context.new_var()
      const HighD = this._context.new_var()
      const LowD = this._context.new_var()

      const HighD0 = interval !== '1D' ? daily_high.get(0) : high_array.get(0)
      const HighD1 = interval !== '1D' ? HighD.get(1) : high_array.get(1)
      const HighD2 = interval !== '1D' ? HighD.get(2) : high_array.get(2)
      const HighD3 = interval !== '1D' ? HighD.get(3) : high_array.get(3)
      const HighD4 = interval !== '1D' ? HighD.get(4) : high_array.get(4)

      const LowD0 = interval !== '1D' ? daily_low.get(0) : low_array.get(0)
      const LowD1 = interval !== '1D' ? LowD.get(1) : low_array.get(1)
      const LowD2 = interval !== '1D' ? LowD.get(2) : low_array.get(2)
      const LowD3 = interval !== '1D' ? LowD.get(3) : low_array.get(3)
      const LowD4 = interval !== '1D' ? LowD.get(4) : low_array.get(4)

      const high_line = (HighD1 + HighD2 + HighD3) / 3
      const low_line = (LowD1 + LowD2 + LowD3) / 3

      const strat_time = () => {
        if (interval === '2') return start_h === 9 && start_m === 1
        else if (interval === '30') return start_h === 9 && start_m === 15
        else return start_h === 9 && start_m === 0
      }

      if (strat_time()) {
        daily_high.set(high)
        daily_low.set(low)
      }

      //記錄每天的最高點
      if (high > daily_high) {
        daily_high.set(high)
      }
      //記錄每天的最低點
      if (low < daily_low) {
        daily_low.set(low)
      }

      if (start_h === 15 && start_m === 0) {
        HighD.set(daily_high.get(0))
        LowD.set(daily_low.get(0))
      }

      let plot_red_line = NaN
      let plot_green_line = NaN
      const start_red_line = this._context.new_var()
      const start_green_line = this._context.new_var()
      const open_time = start_h >= 9
      const close_time = start_h <= 14

      if (strat_time()) {
        start_red_line.set(open + (high_line - low_line) * 0.309)
        start_green_line.set(open - (high_line - low_line) * 0.309)
      }

      if (open_time && close_time) {
        plot_red_line = start_red_line.get(0)
        plot_green_line = start_green_line.get(0)
      } else {
        plot_red_line = NaN
        plot_green_line = NaN
      }

      indicatorsValueStore.bumpyLine = [start_red_line.get(0), start_green_line.get(0)]

      return [plot_red_line, plot_green_line]
    },
  },
  metainfo: {
    _metainfoVersion: 27,
    isTVScript: !1,
    isTVScriptStub: !1,
    is_hidden_study: !1,
    visible: false,
    defaults: {
      styles: {
        plot_0: {
          linestyle: 1,
          linewidth: 2,
          plottype: 2,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#cc3388',
        },
        plot_1: {
          linestyle: 1,
          linewidth: 2,
          plottype: 2,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#11aacc',
        },
      },
    },
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'line',
      },
    ],
    styles: {
      plot_0: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_1: {
        title: 'Plot',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    is_price_study: !0,
    inputs: [],
    scriptIdPart: '',
  },
})
